import React, { FC, useEffect, useState } from 'react';
import { useCourse, useCourses, useModal, useSettings, useToaster } from 'contexts';
import {
    ControlRendererType,
    CourseDirection,
    CourseFormType,
    CourseForWhom,
    CourseLevel,
    CourseSkill,
    DomainType,
    DurationType,
    EventType,
    FormBuilderKeys,
    ModalType,
} from 'enums';
import { AddCourse, Course, Speaker, UpdateCourse } from 'types';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Controller, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { Heading, Loader, Select } from '@kl/components-v6';
import { FormBuilder } from 'containers';
import { FormRow } from 'containers/news/news/styled';
import { enumsToOptions } from 'containers/form-builder/mappers';
import { ErrorMessage } from 'containers/form-builder/styled';
import { getSwaggerError } from 'utils/swagger-errors';

const INITIAL_DATA: AddCourse = {
    image: null,
    name: '',
    isVisible: false,
    formTypes: [],
    isAwareness: false,
    speakers: [],
    additionalImage: null,
    additionalProgram: null,
    announcementDescription: '',
    studyForms: [],
    directionTypes: [],
    forWhom: [],
    skill: CourseSkill.CommunicatesManagers,
    description: '',
    duration: '',
    durationType: DurationType.LongTerm,
    groupEnId: null,
    groupRuId: null,
    whoIsNecessary: '',
    result: '',
    topic: '',
    practicePart: '',
    courseLevel: CourseLevel.Basic,
    languages: [],
};

const General: FC = () => {
    const { updateItem, addItem, item, speakers } = useCourse();
    const { deleteItems } = useCourses();
    const { setToaster } = useToaster();
    const { id, groupId, domain } = useParams();
    const navigate = useNavigate();
    const { setModal } = useModal();
    const [loading, setLoading] = useState<boolean>(false);

    const { t } = useTranslation(['pages/courses', 'common/shared', 'common/errors']);

    const methods = useForm<AddCourse>();

    const {
        watch,
        formState: { errors },
    } = methods;

    const { control } = methods;

    const onSubmit = async (data: FieldValues) => {
        try {
            setLoading(true);

            if (Number(domain as unknown as DomainType) === DomainType.Ru) {
                data = { ...data, groupRuId: groupId };
            } else {
                data = { ...data, groupEnId: groupId };
            }

            if (id) {
                await updateItem({ ...data, id } as UpdateCourse);
                setToaster({
                    type: 'success',
                    message: t('update-general-success'),
                });
            } else {
                const newCourse = await addItem(data as AddCourse);
                setToaster({
                    type: 'success',
                    message: t('add-general-success'),
                });
                navigate(`/course/${groupId}/${domain}/${newCourse.id}`);
            }
        } catch (e) {
            const swagger = getSwaggerError(e);
            if (swagger) {
                setToaster({
                    type: 'error',
                    message: swagger,
                });
                return;
            }
            setToaster({
                type: 'error',
                message: t('backend-error', { ns: 'common/shared' }),
            });
        } finally {
            setLoading(false);
        }
    };

    if (id && !item) {
        return <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />;
    }

    const convert = (course: Course | null): AddCourse | null => {
        if (!course) return null;

        return {
            ...course,
            speakers: course.speakers.map((s) => s.id),
            groupEnId: course?.groupEn?.id,
            groupRuId: course?.groupRu?.id,
        };
    };

    const onDeleteCourse = async () => {
        if (id) {
            setModal(ModalType.Confirm, <div>{t('delete-confirm', { ns: 'common/shared' })}</div>, async () => {
                setLoading(true);

                try {
                    await deleteItems([id]);

                    setToaster({
                        type: 'success',
                        message: t('delete-success', { ns: 'common/shared' }),
                    });

                    navigate('/courses/all');
                } catch (e) {
                    const res = e as {
                        response?: {
                            status?: number;
                            data?: string;
                        };
                    };
                    if (
                        res?.response &&
                        res?.response?.status &&
                        res?.response?.status === 409 &&
                        res?.response?.data
                    ) {
                        setToaster({
                            type: 'error',
                            message: res?.response?.data ?? '',
                        });
                        return;
                    }
                    setToaster({
                        type: 'error',
                        message: t('network-problem'),
                    });
                } finally {
                    setLoading(false);
                }
            });
        }
    };

    const isAwarenessWatch = watch('isAwareness')?.valueOf();

    return (
        <>
            <Heading type={'H2'}>{id ? t('update-general') : t('add-general')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<AddCourse>
                    data={convert(item) || INITIAL_DATA}
                    submit={onSubmit}
                    cancel={() => navigate('/courses/all')}
                    onDelete={
                        item?.groupEn?.name !== 'free-course' && item?.groupRu?.name !== 'free-course'
                            ? onDeleteCourse
                            : undefined
                    }
                    formKey={FormBuilderKeys.CourseGeneral}
                    isFormEmpty={!id}
                    loading={loading}
                >
                    <>
                        <FormRow>
                            <span>{t('speakers', { ns: 'common/shared' })}</span>
                            <Controller
                                name={'speakers'}
                                control={control}
                                // @ts-ignore
                                defaultValue={item?.speakers?.map((speaker) => speaker.id) || []}
                                render={({ field: { onChange } }) => (
                                    <Select
                                        showSearch
                                        mode={'multiple'}
                                        onChange={onChange}
                                        defaultValue={item?.speakers?.map((speaker) => speaker.id)}
                                        options={speakers
                                            ?.filter((r) => Number(domain as unknown as DomainType) === r.domainType)
                                            ?.map((option: Speaker) => ({
                                                label: `${option.firstName} ${option.lastName}${
                                                    option.position ? ` (${option.position})` : ''
                                                }`,
                                                value: option.id,
                                            }))}
                                    />
                                )}
                            />
                        </FormRow>

                        <FormRow>
                            <span>{t('studyForms', { ns: 'common/shared' })}*</span>
                            <Controller
                                name={'studyForms'}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t('required-field', {
                                            ns: 'common/errors',
                                        }),
                                    },
                                }}
                                control={control}
                                defaultValue={item?.studyForms || []}
                                render={({ field: { onChange } }) => {
                                    const enumsKeys = enumsToOptions(ControlRendererType.CourseStudyForms);
                                    return (
                                        <Select
                                            showSearch
                                            mode={'multiple'}
                                            onChange={onChange}
                                            defaultValue={item?.studyForms || []}
                                            error={!!errors.studyForms}
                                            options={enumsKeys.map((option) => ({
                                                ...option,
                                                disabled:
                                                    watch('studyForms').length >= 40 &&
                                                    !watch('studyForms').includes(option.value as EventType),
                                            }))}
                                        />
                                    );
                                }}
                            />
                            {errors.studyForms && <ErrorMessage>{errors.studyForms.message}</ErrorMessage>}
                        </FormRow>

                        <FormRow>
                            <span>{t('directionTypes', { ns: 'common/shared' })}*</span>
                            <Controller
                                name={'directionTypes'}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t('required-field', {
                                            ns: 'common/errors',
                                        }),
                                    },
                                }}
                                control={control}
                                defaultValue={item?.directionTypes || []}
                                render={({ field: { onChange } }) => {
                                    const enumsKeys = enumsToOptions(ControlRendererType.CourseDirectionTypes);
                                    return (
                                        <Select
                                            showSearch
                                            mode={'multiple'}
                                            onChange={onChange}
                                            defaultValue={item?.directionTypes || []}
                                            error={!!errors.directionTypes}
                                            options={enumsKeys.map((option) => ({
                                                ...option,
                                                disabled:
                                                    watch('directionTypes').length >= 40 &&
                                                    !watch('directionTypes').includes(option.value as CourseDirection),
                                            }))}
                                        />
                                    );
                                }}
                            />
                            {errors.directionTypes && <ErrorMessage>{errors.directionTypes.message}</ErrorMessage>}
                        </FormRow>
                        <FormRow>
                            <span>{t('forWhom', { ns: 'common/shared' })}*</span>
                            <Controller
                                name={'forWhom'}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t('required-field', {
                                            ns: 'common/errors',
                                        }),
                                    },
                                }}
                                control={control}
                                defaultValue={item?.forWhom || []}
                                render={({ field: { onChange } }) => {
                                    const enumsKeys = enumsToOptions(ControlRendererType.CourseForWhom);
                                    return (
                                        <Select
                                            showSearch
                                            mode={'multiple'}
                                            onChange={onChange}
                                            defaultValue={item?.forWhom || []}
                                            error={!!errors.forWhom}
                                            options={enumsKeys.map((option) => ({
                                                ...option,
                                                disabled:
                                                    watch('forWhom').length >= 40 &&
                                                    !watch('forWhom').includes(option.value as CourseForWhom),
                                            }))}
                                        />
                                    );
                                }}
                            />
                            {errors.forWhom && <ErrorMessage>{errors.forWhom.message}</ErrorMessage>}
                        </FormRow>
                        {!isAwarenessWatch && (
                            <FormRow>
                                <span>{t('formTypes', { ns: 'common/shared' })}*</span>
                                <Controller
                                    name={'formTypes'}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: t('required-field', {
                                                ns: 'common/errors',
                                            }),
                                        },
                                    }}
                                    control={control}
                                    defaultValue={item?.formTypes || []}
                                    render={({ field: { onChange } }) => {
                                        const enumsKeys = enumsToOptions(ControlRendererType.CourseFormTypes);
                                        return (
                                            <Select
                                                showSearch
                                                mode={'multiple'}
                                                onChange={onChange}
                                                defaultValue={item?.formTypes || []}
                                                error={!!errors.formTypes}
                                                options={enumsKeys.map((option) => ({
                                                    ...option,
                                                    disabled:
                                                        watch('formTypes').length >= 4 &&
                                                        !watch('formTypes').includes(option.value as CourseFormType),
                                                }))}
                                            />
                                        );
                                    }}
                                />
                                {errors.formTypes && <ErrorMessage>{errors.formTypes.message}</ErrorMessage>}
                            </FormRow>
                        )}
                        <FormRow>
                            <span>{t('languages', { ns: 'common/shared' })}*</span>
                            <Controller
                                name={'languages'}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t('required-field', {
                                            ns: 'common/errors',
                                        }),
                                    },
                                }}
                                control={control}
                                defaultValue={item?.languages || []}
                                render={({ field: { onChange } }) => {
                                    const enumsKeys = enumsToOptions(ControlRendererType.Languages);
                                    return (
                                        <Select
                                            showSearch
                                            mode={'multiple'}
                                            onChange={onChange}
                                            defaultValue={item?.languages || []}
                                            error={!!errors.languages}
                                            options={enumsKeys.map((option) => ({
                                                ...option,
                                            }))}
                                        />
                                    );
                                }}
                            />
                            {errors.languages && <ErrorMessage>{errors.languages.message}</ErrorMessage>}
                        </FormRow>
                        {domain && (
                            <FormRow>
                                {Number(domain as unknown as DomainType) === DomainType.Ru ? (
                                    <>
                                        <span>{t('groupRuId', { ns: 'common/shared' })}</span>
                                        <span>{item?.groupRu.name}</span>
                                    </>
                                ) : (
                                    <>
                                        <span>{t('groupEnId', { ns: 'common/shared' })}</span>
                                        <span>{item?.groupEn.name}</span>
                                    </>
                                )}
                            </FormRow>
                        )}
                    </>
                </FormBuilder>
            </FormProvider>
        </>
    );
};

export default General;
